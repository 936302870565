/* https://gwfh.mranftl.com/fonts/roboto?subsets=cyrillic,latin */

/* roboto-300 - cyrillic_latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/roboto-v30-cyrillic_latin-300.eot'); /* IE9 Compat Modes */
	src: url('../fonts/roboto-v30-cyrillic_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/roboto-v30-cyrillic_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/roboto-v30-cyrillic_latin-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/roboto-v30-cyrillic_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/roboto-v30-cyrillic_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - cyrillic_latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	src: url('../fonts/roboto-v30-cyrillic_latin-300italic.eot'); /* IE9 Compat Modes */
	src: url('../fonts/roboto-v30-cyrillic_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/roboto-v30-cyrillic_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/roboto-v30-cyrillic_latin-300italic.woff') format('woff'), /* Modern Browsers */
	url('../fonts/roboto-v30-cyrillic_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/roboto-v30-cyrillic_latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - cyrillic_latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/roboto-v30-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
	src: url('../fonts/roboto-v30-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/roboto-v30-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/roboto-v30-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/roboto-v30-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/roboto-v30-cyrillic_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}